// api.js

const API_URL = 'https://nyx-play-api.onrender.com/games';
const API_KEY = process.env.REACT_APP_API_KEY;

/**
 * Fetches the list of games from the API.
 * @returns {Promise<Object[]>} The list of games.
 * @throws {Error} If the request fails or the response is not OK.
 */
const fetchGames = async () => {
  try {
    if (!API_KEY) {
      throw new Error('API_KEY is not defined');
    }

    const headers = new Headers({
      'Authorization': `Bearer ${API_KEY}`,
    });

    const response = await fetch(API_URL, { headers });

    if (!response.ok) {
      const errorDetails = await response.text();
      throw new Error(`Failed to fetch games data: ${response.status} ${response.statusText} - ${errorDetails}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error fetching games:', error.message);
    throw new Error(error.message);
  }
};

/**
 * Fetches a specific game by its ID from the API.
 * @param {number|string} id - The ID of the game to fetch.
 * @returns {Promise<Object>} The game data.
 * @throws {Error} If the request fails or the response is not OK.
 */
const fetchGameById = async (id) => {
  try {
    if (!API_KEY) {
      throw new Error('API_KEY is not defined');
    }

    const response = await fetch(`${API_URL}/${id}`, {
      headers: {
        'Authorization': `Bearer ${API_KEY}`,
      },
    });

    if (!response.ok) {
      const errorDetails = await response.text();
      throw new Error(`Failed to fetch game data: ${response.status} ${response.statusText} - ${errorDetails}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error fetching game by ID:', error.message);
    throw new Error(error.message);
  }
};

module.exports = { fetchGames, fetchGameById };
