// src/App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import GameList from './components/GameList';
import GamePlayer from './components/GamePlayer';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfService from './components/TermsOfService';
import './App.css';

const Footer = () => (
  <footer className="footer">
    <p>&copy; Nyx Play 2024. All rights reserved.</p>
    <p>
      Follow us on{' '}
      <a href="https://x.com/NyxPlay__?t=Y7ZF7STiukD56zupPrb94A&s=09" target="_blank" rel="noopener noreferrer">Twitter</a>,{' '}
      <a href="https://www.facebook.com/profile.php?id=61565341744188" target="_blank" rel="noopener noreferrer">Facebook</a>, and{' '}
      <a href="https://www.instagram.com/nyx_play?igsh=MTFhNmIyZjhyOXlvcg==" target="_blank" rel="noopener noreferrer">Instagram</a>.
    </p>
    <p>
      <a href="/privacy-policy">Privacy Policy</a> | <a href="/terms-of-service">Terms of Service</a>
    </p>
  </footer>
);

function App() {
  const [isGamePlaying, setIsGamePlaying] = useState(false);

  return (
    <Router>
      <div className="app">
        <Routes>
          <Route path="/" element={<GameList />} />
          <Route
            path="/game/:id"
            element={<GamePlayer onPlayStateChange={setIsGamePlaying} />}
          />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
        </Routes>
        {!isGamePlaying && <Footer />}
      </div>
    </Router>
  );
}

export default App;
