import React, { useState, useEffect } from 'react';
import GameItem from './GameItem';
import CategoryFilter from './CategoryFilter';
import { fetchGames } from '../api'; // Import fetchGames function
import './GameList.css'; // Import the CSS file

const PAGE_SIZE = 20; // Number of games per page

const GameList = () => {
  const [gamesData, setGamesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [currentPage, setCurrentPage] = useState(() => Number(localStorage.getItem('currentPage')) || 1);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const fetchGamesData = async () => {
      setLoading(true);
      try {
        const data = await fetchGames();
        setGamesData(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchGamesData();
  }, []);

  // Filter Daily Games
  const dailyGames = gamesData.filter((game) => game.category === 'Daily Games');

  // Filter other games based on category and search query
  const filteredGames = gamesData.filter((game) =>
    (selectedCategory === 'All' || game.category === selectedCategory) &&
    game.category !== 'Daily Games' && // Exclude Daily Games from regular games list
    game.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const startIndex = (currentPage - 1) * PAGE_SIZE;
  const endIndex = startIndex + PAGE_SIZE;
  const paginatedGames = filteredGames.slice(startIndex, endIndex);

  const totalPages = Math.ceil(filteredGames.length / PAGE_SIZE);

  const handlePageChange = (pageNumber) => {
    localStorage.setItem('currentPage', pageNumber);
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedCategory, searchQuery]);

  useEffect(() => {
    localStorage.setItem('currentPage', currentPage);
  }, [currentPage]);

  if (loading) {
    return (
      <div className="loading-container">
        <div className="spinner"></div>
      </div>
    );
  }
  

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="game-list-container">
      <CategoryFilter
        selectedCategory={selectedCategory}
        onCategoryChange={setSelectedCategory}
      />
      <input
        type="text"
        placeholder="Search games..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        className="search-input"
      />

      {/* Daily Games Section */}
      {dailyGames.length > 0 && (
        <div className="daily-games-container">
          <h2>Daily Challenge</h2>
          <div className="daily-games">
            {dailyGames.map((game) => (
              <GameItem key={game.id} game={game} />
            ))}
          </div>
        </div>
      )}

      {/* Regular Games Section */}
      <div className="game-list">
        {paginatedGames.map((game) => (
          <GameItem key={game.id} game={game} />
        ))}
      </div>

      {/* Pagination Controls */}
      <div className="pagination-controls">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index + 1}
            onClick={() => handlePageChange(index + 1)}
            className={currentPage === index + 1 ? 'active' : ''}
          >
            {index + 1}
          </button>
        ))}
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default GameList;
