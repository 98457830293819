import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import GameItem from './GameItem';
import { fetchGameById, fetchGames } from '../api'; // Import API functions
import { Helmet } from 'react-helmet';
import './GamePlayer.css';

const GamePlayer = ({ onPlayStateChange }) => {
  const { id } = useParams();
  const [game, setGame] = useState(null);
  const [relatedGames, setRelatedGames] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchGameData = async () => {
      setLoading(true);
      try {
        const data = await fetchGameById(id);
        setGame(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchGameData();
  }, [id]);

  useEffect(() => {
    if (game) {
      const fetchRelatedGamesData = async () => {
        try {
          const data = await fetchGames();
          const filteredRelatedGames = data.filter(
            (g) => g.category === game.category && g.id !== game.id
          );
          setRelatedGames(filteredRelatedGames);
        } catch (error) {
          setError(error.message);
        }
      };
      fetchRelatedGamesData();
    }
  }, [game]);

  const handlePlayClick = () => {
    setIsPlaying(true);
    onPlayStateChange(true);
  };

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkIsMobile();
    window.addEventListener('resize', checkIsMobile);

    return () => {
      window.removeEventListener('resize', checkIsMobile);
    };
  }, []);

  if (loading) {
    return (
      <div className="loading-container">
        <div className="spinner"></div>
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!game) {
    return <div>Game not found</div>;
  }

  return (
    <div className="game-player">
      <Helmet>
        <title>{game.title} - Nyx Play</title>
        <meta name="description" content={game.description} />
      </Helmet>
      {!isPlaying ? (
        <div className="thumbnail-container">
          <div
            className="thumbnail-blur-background"
            style={{ backgroundImage: `url(${game.thumbnail})` }}
          ></div>
          <div className="thumbnail-overlay">
            <img src={game.thumbnail} alt={game.title} className="thumbnail-preview" />
            <button className="play-button" onClick={handlePlayClick}>
              Play Now
            </button>
          </div>
        </div>
      ) : (
        <div className="iframe-container">
          <iframe
            src={game.url}
            title={game.title}
            frameBorder="0"
            allow="fullscreen; touch-action"
            sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
            allowFullScreen
          ></iframe>
        </div>
      )}

      {!isMobile && relatedGames.length > 0 && (
        <div className="related-games">
          <h3>More games in {game.category} Category</h3>
          <div className="game-list">
            {relatedGames.map((relatedGame) => (
              <GameItem key={relatedGame.id} game={relatedGame} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default GamePlayer;
