// src/components/CategoryFilter.js
import React from 'react';
import './CategoryFilter.css'; // Import the CSS file

const categories = [
  'All',
  'Platformer',
  'Arcade',
  'Puzzle',
  'Action',
  'Mahjong',
  'Match-3',
  'Solitaire',
];

const CategoryFilter = ({ selectedCategory, onCategoryChange }) => {
  return (
    <div className="category-filter">
      <nav className="category-nav">
        {categories.map((category) => (
          <button
            key={category}
            className={`category-button ${
              selectedCategory === category ? 'active' : ''
            }`}
            onClick={() => onCategoryChange(category)}
          >
            {category}
          </button>
        ))}
      </nav>
    </div>
  );
};

export default CategoryFilter;
